<script>
import { Line, mixins } from "vue-chartjs";
import chartjsPluginAnnotation from "chartjs-plugin-annotation";
export default {
  extends: Line,
  name: "chart",
  mixins: [mixins.reactiveProp],
  props: ["options"],

  mounted() {
    // console.log('renderChart', this.chartData, this.options)
    this.addPlugin(chartjsPluginAnnotation);
    this.renderChart(this.chartData, this.options);
  }
};
</script>